import React from "react";

const Settlement = ({ settlement }) => {
  console.log("settlement", settlement);


  return (
    <div>
      {settlement?.length > 0 ? (
        settlement.map((data, index) => (
          <div
            key={index}
            className="col-12 bg-white p-3"
            style={{ borderRadius: "10px" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-start">
                Date: {`${data?.startDate} To ${data?.endDate}`}
              </div>
            </div>
            <div className="mt-3">
              <div
                className="d-flex align-items-center justify-content-between py-2"
                style={{ borderBottom: "1px solid lightgray" }}
              >
                <div className="text-start fw-bold text-dark">
                  Total Coins : {`${data?.coin} + `}
                  <span
                    style={{
                      color: data?.hostBonus > 0 ? "green" : "success-color", // Replace 'success-color' with your actual color
                    }}
                  >
                    {data?.hostBonus}
                  </span>{" "}
                  ({data?.hostBonusPercent}%) = {data?.totalCoin}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center align-items-center my-4 bg-white">
          <span>No data found.</span>
        </div>
      )}
    </div>
  );
};

export default Settlement;
