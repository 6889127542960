import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import leftArrow from "../../assets/images/leftArrow.svg";


const DailyIncome = () => {
  const { hostHitory } = useSelector((state) => state.history);
  console.log("hostHitory", hostHitory);
  const history = useHistory();

  const handleOpenHistory = (data) => {
    history.push("/host/historyInfo", { state: data?.date });
  };
  return (
    <>
      {hostHitory?.length > 0 ? (
        <>
          {hostHitory.map((data) => {
            return (
              <>
                <div
                  className="col-12 bg-white p-3 my-3"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mb-0 text-bfg fw-bold">
                      {moment(data?.date).format("DD-MM")}
                    </h6>
                    <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                        onClick={() => handleOpenHistory(data)}
                      />
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "gray",
                          fontSize: "11px",
                          fontWeight: "500",
                        }}
                      >
                        Total
                      </span>
                      <div className="d-flex align-items-center">
                        <img
                          src={require("../../assets/images/rcoin.png")}
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                        />
                        <span
                          className="mb-0 fw-bolder"
                          style={{
                            color: "#FFBB0B",
                            fontSize: "15px",
                          }}
                        >
                          {data?.totalRCoin ? data?.totalRCoin : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center my-4">
            <span>No data found.</span>
          </div>
        </>
      )}
    </>
  );
};

export default DailyIncome;
