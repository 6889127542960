import React, { forwardRef, useEffect, useState } from "react";

// js
import "../assets/js/main.min.js";

//router
import { NavLink as Link, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// component

import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/admin/action.js";
import male from "../assets/images/male.png";
import moment from "moment";
import {
  getHostEarning,
  getHosthistory,
  getHostSettelement,
} from "../store/history/history.action.js";
import DailyIncome from "./history/DailyIncome.js";
import RoomData from "./history/RoomData.js";
import VideoCall from "./history/VideoCall.js";
import { getLiveRoomHistory } from "../store/liveRoom/action.js";
import { getChatRoomHistory } from "../store/chatRoom/action.js";
import { getVideoCallHistory } from "../store/videoCall/action.js";
import { permissionError } from "../util/Alert.js";
import { Toast } from "../util/Toast.js";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Settlement from "./history/Settlement.js";
import CopyImage from "../assets/images/copy.svg";

const Admin = () => {
  // const location = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector((state) => state.admin.seller);
  const hostHistory = useSelector((state) => state.history.hostSettlement);
  const summary = useSelector((state) => state.history.summaryData);

  console.log("summary", summary);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [imagePath, setImagePath] = useState();
  const hostId = localStorage.getItem("hostId");
  const startAllDate = "1970-01-01";
  const endAllDate = dayjs().format("YYYY-MM-DD");

  console.log("hostSettlement", hostHistory);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [type, setType] = useState(1);

  useEffect(() => {
    dispatch(getProfile(hostId));
    dispatch(getHosthistory(startDate, endDate, hostId));
    dispatch(getHostEarning(hostId, startDate, endDate));
    dispatch(getVideoCallHistory(hostId, startDate, endDate));
    dispatch(getChatRoomHistory(hostId, startDate, endDate));
    dispatch(getLiveRoomHistory(hostId, startDate, endDate));
    dispatch(getHostSettelement(hostId, startDate, endDate));
  }, [dispatch, startDate, endDate, hostId]);

  useEffect(() => {
    setImagePath(admin?.image);
  });

  const handleBack = () => {
    window.showAndroidToast();
  };

  const handleCopy = async (link) => {
    if (admin?.isActive === false) return permissionError();
    try {
      await navigator.clipboard.writeText(link); // Use await to ensure clipboard action is completed.
      Toast("success", `Copy Success.`);
    } catch (error) {
      console.error("Copy failed:", error);
      Toast("error", "Copy Failed");
    }
  };

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    dispatch(getHostSettelement(hostId, start, end));
  };


  return (
    <>
      <div class="page-container">
        <div class="page-content">
          <div class="main-wrapper p-0">
            <div className="main-section">
              <div
                className="row p-3 mb-2 bg-white "
                style={{
                  zIndex: "9",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                }}
              >
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    // onClick={() => window.history.back()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="col-5 text-center ">
                  <p
                    className="text-dark fw-bold mb-0"
                    style={{ fontSize: "15px" }}
                  >
                    Creator Center
                  </p>
                </div>
              </div>
              <div className="px-3 " style={{ marginTop: "65px" }}>
                <div className="d-flex justify-content-between mt-3">
                  <div className="bd-content">
                    <div className="d-flex">
                      <div>
                        <img
                          src={admin?.image ? admin?.image : male}
                          style={{
                            height: "40px",
                            width: "40px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                      </div>
                      <div className="ms-3">
                        <p
                          className="mb-0 fw-bolder text-dark"
                          style={{ fontSize: "15px" }}
                        >
                          {admin?.name ? admin?.name : "-"}
                        </p>
                        <p className="fw-bolder" style={{ fontSize: "12px" }}>
                          ID : {admin?.uniqueId ? admin?.uniqueId : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="date-picker">
                  <div className="date-picker">
                    <DateRangePicker
                      initialSettings={{
                        startDate: undefined,
                        endDate: undefined,
                        ranges: {
                          All: [new Date("1970-01-01"), dayjs().toDate()],
                          Today: [dayjs().toDate(), dayjs().toDate()],
                          Yesterday: [
                            dayjs().subtract(1, "days").toDate(),
                            dayjs().subtract(1, "days").toDate(),
                          ],

                          "Last 7 Days": [
                            dayjs().subtract(6, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "Last 30 Days": [
                            dayjs().subtract(29, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "This Month": [
                            dayjs().startOf("month").toDate(),
                            dayjs().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            dayjs()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            dayjs()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                        },
                        maxDate: new Date(),
                        singleDatePicker: false,
                        linkedCalendars: false,
                      }}
                      onApply={handleApply}
                    >
                      <input
                        type="text"
                        readOnly
                        placeholder="Select Date Range"
                        // onClick={handleInputClick}
                        className={`daterange float-right  mr-4  text-center`}
                        value={
                          (startDate === startAllDate &&
                            endDate === endAllDate) ||
                          (startDate === "All" && endDate === "All")
                            ? "Select Date Range"
                            : dayjs(startDate).format("MM/DD/YYYY") &&
                              dayjs(endDate).format("MM/DD/YYYY")
                            ? `${dayjs(startDate).format(
                                "MM/DD/YYYY"
                              )} - ${dayjs(endDate).format("MM/DD/YYYY")}`
                            : "Select Date Range"
                        }
                        style={{
                          fontWeight: 500,
                          cursor: "pointer",
                          background: "#F0F0F0",
                          color: "#000",
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                          fontSize: "13px",
                          padding: "10px",
                          maxWidth: "226px",
                          borderRadius: "64px",
                          border: "1px solid transparent",
                          height: "48px !important",
                          outline: "none",
                        }}
                      />
                    </DateRangePicker>
                  </div>
                </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="host-detail">
                      <div className="d-flex align-items-center pt-3 ps-3 pb-3">
                        <h4 className="text-dark mb-0">Total:</h4>
                        <img
                          src={require("../assets/images/rcoin.png")}
                          style={{ height: "30px", width: "30px" }}
                          className="ms-1"
                          alt=""
                        />
                        <span style={{ color: "#FFBB0B", fontSize: "20px" }}>
                          {summary?.finalCoin ? summary?.finalCoin : 0}
                        </span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FFFBF0",
                          borderRadius: "0px 0px 15px 15px",
                        }}
                      >
                        <div className="row pt-3 ps-3 pb-3">
                          <div className="col-6">
                            <span
                              style={{
                                color: "gray",
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              Basic Income
                            </span>
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "20px", width: "20px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                }}
                              >
                                {summary?.totalCoin ? summary?.totalCoin : 0}
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <span
                              style={{
                                color: "gray",
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              Bonus Income
                            </span>
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "20px", width: "20px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                }}
                              >
                                {summary?.totalHostBonus
                                  ? summary?.totalHostBonus
                                  : 0}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <span
                              style={{
                                color: "gray",
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              This week income
                            </span>
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "20px", width: "20px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                }}
                              >
                                {admin?.currentCoin ? admin?.currentCoin : 0}
                              </span>
                            </div>
                          </div>

                          <div className="col-6 mt-2">
                            <span
                              style={{
                                color: "gray",
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              Coin (In wallet)
                            </span>
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "20px", width: "20px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                }}
                              >
                                {admin?.rCoin ? admin?.rCoin : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=" p-2 mt-3"
                  style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fw-bold mb-0 text-dark">My Agency</h6>
                    <div>
                      <div className="d-flex align-items-center justify-content-end">
                        <img
                          src={
                            admin?.hostAgency?.image
                              ? admin?.hostAgency?.image
                              : male
                          }
                          style={{
                            height: "28px",
                            width: "28px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                        <h6 className="fw-bold ms-2 mb-0 text-dark">
                          {admin?.hostAgency?.name
                            ? admin?.hostAgency?.name
                            : "-"}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-end mt-2">
                        <h6 className="fw-bold mb-0 text-dark">
                          ID :{" "}
                          {admin?.hostAgency?.uniqueId
                            ? admin?.hostAgency?.uniqueId
                            : "-"}
                        </h6>
                        <button
                          className="ms-1 pe-0"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() =>
                            handleCopy(admin?.hostAgency?.uniqueId)
                          }
                        >
                          <img
                            src={CopyImage}
                            style={{
                              height: "20px",
                              width: "20px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          {/* <i class="fa-regular fa-copy"></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around mt-4 mb-3">
                  <div className="ms-2">
                    <h4
                      className={`${
                        type === 1 ? "text-dark fw-bold" : "text-gray"
                      } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(1)}
                    >
                      Daily Income
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 1 && (
                          <hr className="text-dark d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>
                  <div className="ms-2">
                    <h4
                      className={`${
                        type === 2 ? "text-dark fw-bold" : "text-gray"
                      } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(2)}
                    >
                      Room Data
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 2 && (
                          <hr className="text-dark d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>
                  <div className="ms-2">
                    <h4
                      className={`${
                        type === 3 ? "text-dark fw-bold" : "text-gray"
                      } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(3)}
                    >
                      Video Call
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 3 && (
                          <hr className="text-dark d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>

                  <div className="ms-2">
                    <h4
                      className={`${
                        type === 4 ? "text-dark fw-bold" : "text-gray"
                      } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(4)}
                    >
                      Settlement
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 4 && (
                          <hr className="text-dark d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>
                </div>

                {type === 1 && (
                  <>
                    <DailyIncome />
                  </>
                )}
                {type === 2 && (
                  <>
                    <RoomData />
                  </>
                )}
                {type === 3 && (
                  <>
                    <VideoCall />
                  </>
                )}

                {type === 4 && (
                  <>
                    <Settlement settlement={hostHistory} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
