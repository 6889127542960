import { GET_HOST_CHAT_ROOM_HISTORY } from "./types";

const initialState = {
  chatRoomHistory: [],
  totalCommission: 0,
};

const chatRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_CHAT_ROOM_HISTORY:
      return {
        ...state,
        chatRoomHistory: action.payload.history,
      };
    default:
      return state;
  }
};

export default chatRoomReducer;
