// ADMIN HISTORY

export const GET_ADMIN_HISTORY = "GET_ADMIN_HISTORY";

export const GET_ADMIN_EARNING = "GET_ADMIN_EARNING";

// USER HISTORY
export const GET_ADMIN_CASHOUT = "GET_ADMIN_CASHOUT";

export const GET_HOST_HISTORY = "GET_HOST_HISTORY";
export const GET_HOST_HISTORY_INFO = "GET_HOST_HISTORY_INFO";

export const GET_HOST_SETTLEMENT = "GET_HOST_SETTLEMENT";





