import React from "react";
import { useSelector } from "react-redux";

const VideoCall = () => {
  const { videoCallHistory } = useSelector((state) => state.videoCallHistory);
  return (
    <div>
      <div className="col-12 bg-white p-3" style={{ borderRadius: "10px" }}>
        <div className="d-flex align-items-center justify-content-between ">
          <div className="text-start">Date</div>
          <div className="text-center">Duration (Sec)</div>
        </div>
        <div className="mt-3">
          {videoCallHistory?.length > 0 ? (
            <>
              {videoCallHistory?.map((data) => {
                return (
                  <>
                    <div
                      className="d-flex align-items-center justify-content-between py-2"
                      style={{ borderBottom: "1px solid lightgray" }}
                    >
                      <div className="text-start fw-bold text-dark">
                        {data?.date}
                      </div>
                      <div className="text-center fw-bold text-dark">
                        {data?.callDurationSeconds ? data?.callDurationSeconds : 0}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center my-4 bg-white">
                <span>No data found.</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
