import axios from "axios";
import * as ActionType from "./history.type";

// get admin history

export const getAdminHistory = (id, start, limit, date) => (dispatch) => {
  axios
    .get(
      `weekHistory/getAgencyHistory?hostId=${id}&start=${start}&limit=${limit}&month=${date}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};
export const getAdminCashOut = (id, start, limit, date, type) => (dispatch) => {
  axios
    .get(
      `agencyRedeem/getAgencyWise?hostId=${id}&start=${start}&limit=${limit}&month=${date}&type=${type}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_CASHOUT,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHosthistory = (startDate, endDate , hostId) => (dispatch) => {
  axios
    .get(`host/topCreators?startDate=${startDate}&endDate=${endDate}&hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_HOST_HISTORY,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHostSettelement = (id, startDate , endDate) => (dispatch) => {
  axios
    .get(`host/hostSettlementForHost?startDate=${startDate}&endDate=${endDate}&hostId=${id}`)
    .then((res) => {

      console.log('res', res)
      dispatch({
        type: ActionType.GET_HOST_SETTLEMENT,
        payload: { history: res.data.history , summary : res.data.summary },
      }); 
    })
    .catch((error) => console.log("error", error));
};

export const getHostEarning = (id, date) => (dispatch) => {
  axios
    .get(`host/hostEarning?hostId=${id}&month=${date}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.data },
      });
    })
    .catch((error) => console.log("error", error));
};



export const getHosthistoryInfo = (date, hostId) => (dispatch) => {
  axios
    .get(`host/hostHistoryByDate?date=${date}&hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_HOST_HISTORY_INFO,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};


